import startCase from 'lodash/startCase'
import { statsigLog } from '../../lib/statsigAndGa'

export const getTypeName = (typeId: string, list = []) =>
  list.find(v => v.name === startCase(typeId))?.name

export const blogClick = (name: string, blog) => {
  statsigLog(name, {
    blog_id: blog.id
  })
}

export const propertyClick = (name: string, property) => {
  statsigLog(name, {
    property_id: property.id
  })
}
