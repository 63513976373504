export default function instagram(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.4452C0 19.567 5.00872 24.5757 11.1305 24.5757H12.8695C18.9913 24.5757 24 19.567 24 13.4452V11.7062C24 5.5844 18.9913 0.575684 12.8695 0.575684H11.1305C5.00872 0.575684 0 5.5844 0 11.7062V13.4452ZM8.15805 12.5756C8.15805 10.4538 9.87813 8.73373 12 8.73373C14.1218 8.73373 15.8419 10.4538 15.8419 12.5756C15.8419 14.6975 14.1218 16.4175 12 16.4175C9.87813 16.4175 8.15805 14.6975 8.15805 12.5756ZM9.49996 12.5756C9.49996 13.9564 10.6193 15.0756 12 15.0756C13.3807 15.0756 14.5 13.9564 14.5 12.5756C14.5 11.1949 13.3807 10.0756 12 10.0756C10.6192 10.0756 9.49996 11.1949 9.49996 12.5756ZM15.2597 5.12868C17.6015 5.12868 19.447 6.9741 19.447 9.31595C19.447 9.31595 19.5 10.9371 19.5 12.5227C19.5 14.1435 19.447 15.8354 19.447 15.8354C19.447 18.1773 17.6016 20.0227 15.2597 20.0227C15.2597 20.0227 13.4956 20.0757 11.841 20.0757C10.2924 20.0757 8.7933 20.0227 8.7933 20.0227C6.45145 20.0227 4.553 18.1242 4.553 15.7824C4.553 15.7824 4.5 14.0712 4.5 12.4167C4.5 10.8681 4.553 9.36898 4.553 9.36898C4.553 7.02713 6.45145 5.12868 8.7933 5.12868C8.7933 5.12868 10.3967 5.07568 12 5.07568C13.6033 5.07568 15.2597 5.12868 15.2597 5.12868ZM15.0005 18.6821C16.9209 18.6821 18.1065 17.4965 18.1065 15.5762C18.1065 15.5762 18.15 13.8612 18.15 12.5322C18.15 11.2321 18.1065 9.57511 18.1065 9.57511C18.1065 7.65479 16.9209 6.46913 15.0005 6.46913C15.0005 6.46913 13.3148 6.42565 12 6.42565C10.6852 6.42565 9.05247 6.46913 9.05247 6.46913C7.1322 6.46913 5.89348 7.65479 5.89348 9.57511C5.89348 9.57511 5.85 11.1754 5.85 12.4453C5.85 13.802 5.89348 15.5232 5.89348 15.5232C5.89348 17.4434 7.13217 18.6821 9.05247 18.6821C9.05247 18.6821 10.5997 18.7256 11.8696 18.7256C13.2264 18.7256 15.0005 18.6821 15.0005 18.6821Z"
        fill={props?.color || '#717171'}
      />
    </svg>
  )
}
