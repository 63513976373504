export default function facebook(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0.575684C5.36836 0.575684 0 5.94404 0 12.5757C0 19.2073 5.36836 24.5757 12 24.5757C18.6316 24.5757 24 19.2085 24 12.5757C24 5.94287 18.6328 0.575684 12 0.575684ZM15.2203 12.5126H13.137V20.0288H10.0432V12.5126H8.46305V9.92373H10.0423V8.34451C10.0423 6.26115 10.9266 4.99764 13.3891 4.99764H15.4725V7.58678H14.209C13.2621 7.58678 13.1991 7.966 13.1991 8.5967V9.86022H15.5353L15.2196 12.5129L15.2203 12.5126Z"
        fill={props?.color || '#717171'}
      />
    </svg>
  )
}
