import { Box } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import LinkList from '../nearSearches/linkList'

const NearSearches = ({ city }) => {
  const intl = useIntl()
  const [showAll, setShowAll] = useState(false)
  return (
    <Box className="bg-white pt-[20px] md:pt-[60px] pb-[60px] flex justify-center">
      <Box className="w-full max-w-[1160px] flex flex-col">
        <Box className="h-full lg:h-fit flex flex-col items-center gap-16">
          <Box className="bg-white text-primary px-5 md:px-0 pt-10 py-4 overflow-y-scroll hide-scrollbar">
            <Box
              component="div"
              className="text-xl font-semibold flex items-center justify-between"
            >
              <Box> {intl.formatMessage({ id: 'home.nearby.searches.title' })}</Box>
            </Box>
            <LinkList cities={[city]} showAll={showAll} />
          </Box>
        </Box>
        {!showAll ? (
          <Box
            onClick={() => setShowAll(true)}
            className="text-sm underline cursor-pointer font-semibold px-5 md:px-0"
          >
            {intl.formatMessage({ id: 'home.nearby.searches.show.more' })}
          </Box>
        ) : (
          <Box
            onClick={() => setShowAll(false)}
            className="text-sm underline cursor-pointer font-semibold px-5 md:px-0"
          >
            {intl.formatMessage({ id: 'home.nearby.searches.show.less' })}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default NearSearches
