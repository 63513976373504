import { Box } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { generatePath } from '@/lib/urlParse'
import { statsigLog } from '@/lib/statsigAndGa'
import { useIntl } from 'react-intl'
import Facebook from '../icons/newHome/facebook'
import Twitter from '../icons/newHome/twitter'
// import Linkedin from '../icons/newHome/linkedin'
import TikTok from '../icons/newHome/tikTok'
import Instagram from '../icons/newHome/instagram'
import Youtube from '../icons/newHome/youtube'

const CURRENT_YEAR = new Date().getFullYear()

const FooterContent = ({ isPC }) => {
  const intl = useIntl()
  // const [expand, setExpand] = useState(false)
  // const onClickExpand = () => {
  //   setExpand(true)
  // }
  const footerContentList = [
    {
      key: 'city',
      name: intl.formatMessage({ id: 'home.footer.cities.title' }),
      content: [
        {
          name: 'Los Angeles',
          url: generatePath('Los Angeles')
        },
        {
          name: 'Houston',
          url: generatePath('Houston')
        },
        {
          name: 'Irving',
          url: generatePath('Irving')
        },
        {
          name: 'Killeen',
          url: generatePath('Killeen')
        },
        {
          name: 'Corpus Christi',
          url: generatePath('Corpus Christi')
        }
      ]
    },
    {
      key: 'neighborhoods',
      name: intl.formatMessage({ id: 'home.footer.neighborhoods.title' }),
      content: [
        {
          name: 'USC',
          url: generatePath('Los Angeles', 'USC'),
          city: 'Los Angeles',
          area: 'USC'
        },
        {
          name: 'Koreatown',
          url: generatePath('Los Angeles', 'Koreatown'),
          city: 'Los Angeles',
          area: 'Koreatown'
        },
        {
          name: 'West LA',
          url: generatePath('Los Angeles', 'West LA'),
          city: 'Los Angeles',
          area: 'West LA'
        },
        {
          name: 'Hollywood',
          url: generatePath('Los Angeles', 'Hollywood'),
          city: 'Los Angeles',
          area: 'Hollywood'
        },
        {
          name: 'DTLA',
          url: generatePath('Los Angeles', 'DTLA'),
          city: 'Los Angeles',
          area: 'DTLA'
        }
      ]
    },
    {
      key: 'blog',
      name: intl.formatMessage({ id: 'home.footer.blogs.title' }),
      content: [
        { name: 'All Posts', type: 'all', url: '/blog' },
        { name: 'Coliving', type: 'coliving', url: '/blog' },
        { name: 'Local Highlights', type: 'local-highlights', url: '/blog' },
        { name: 'Apartment Hunting Tips', type: 'apartment-hunting-tips', url: '/blog' },
        { name: 'News', type: 'news', url: '/blog' }
      ]
    },
    {
      key: 'company',
      name: intl.formatMessage({ id: 'home.footer.company.title' }),
      content: [
        {
          name: intl.formatMessage({ id: 'home.footer.company.content1' }),
          url: '/about'
        }
      ]
    }
  ]
  const socialIcon = (target, fillColor) => [
    <Link
      title="Renty Facebook"
      href="https://www.facebook.com/RentyAi"
      key="facebook"
      target={target || '_self'}
    >
      <Facebook className="cursor-pointer text-2xl" color={fillColor} />
    </Link>,
    <Link
      title="Renty Twitter"
      href="https://twitter.com/RentyAi"
      key="twitter"
      target={target || '_self'}
    >
      <Twitter className="cursor-pointer text-2xl" color={fillColor} />
    </Link>,
    <Link
      title="Renty Instagram"
      href="https://instagram.com/renty.ai?igshid=OGQ5ZDc2ODk2ZA=="
      key="instagram"
      target={target || '_self'}
    >
      <Instagram className="cursor-pointer text-2xl" color={fillColor} />
    </Link>,
    <Link
      title="Renty Youtube"
      href="https://www.youtube.com/channel/UCUFb-jAiIo8bsVgYHEfXpTQ"
      key="youtube"
      target={target || '_self'}
    >
      <Youtube className="cursor-pointer text-2xl" color={fillColor} />
    </Link>,
    <Link
      title="Renty Tiktok"
      href="https://www.tiktok.com/@renty.ai"
      key="Tiktok"
      target={target || '_self'}
    >
      <TikTok className="cursor-pointer text-2xl" color={fillColor} />
    </Link>
  ]
  // const areaListOne = [
  //   {
  //     name: 'University District',
  //     url: generatePath('Seattle', 'Downtown'), // /apartments-for-rent/seattle-wa
  //     city: 'Seattle',
  //     area: 'Downtown'
  //   },
  //   {
  //     name: 'Downtown Seattle',
  //     url: generatePath('Seattle', 'Downtown Seattle'), // /apartments-for-rent/seattle-wa/apartments-near-downtown-seattle
  //     city: 'Seattle',
  //     area: 'Downtown Seattle'
  //   },
  //   {
  //     name: 'West Seattle',
  //     url: generatePath('Seattle', 'West Seattle'), // /apartments-for-rent/seattle-wa/studio-apartments-west-seattle
  //     city: 'Seattle',
  //     area: 'West Seattle'
  //   },
  //   {
  //     name: 'Ballard',
  //     url: generatePath('Seattle', 'Ballard'), // /apartments-for-rent/seattle-wa/studio-apartments-ballard
  //     city: 'Seattle',
  //     area: 'Ballard'
  //   },
  //   {
  //     name: 'Fremont',
  //     url: generatePath('Seattle', 'Fremont'), // /apartments-for-rent/seattle-wa/studio-apartments-fremont
  //     city: 'Seattle',
  //     area: 'Fremont'
  //   }
  // ]
  // const areaListTwo = [
  //   {
  //     name: 'University City',
  //     url: generatePath('Philadelphia', 'University City'),
  //     city: 'Philadelphia',
  //     area: 'University City'
  //   },
  //   {
  //     name: 'Temple University',
  //     url: generatePath('Philadelphia', 'Temple University'),
  //     city: 'Philadelphia',
  //     area: 'Temple University'
  //   },
  //   {
  //     name: 'Squirrel Hill',
  //     url: generatePath('Pittsburgh', 'Squirrel Hill'),
  //     city: 'Pittsburgh',
  //     area: 'Squirrel Hill'
  //   },
  //   {
  //     name: 'Oakland',
  //     url: generatePath('Pittsburgh', 'Oakland'),
  //     city: 'Pittsburgh',
  //     area: 'Oakland'
  //   },
  //   {
  //     name: 'UCI Area',
  //     url: generatePath('Irvine', 'UCI Area'),
  //     city: 'Irvine',
  //     area: 'UCI Area'
  //   },
  //   {
  //     name: 'UA',
  //     url: generatePath('Tucson', 'UA'),
  //     city: 'Tucson',
  //     area: 'UA'
  //   }
  // ]
  const FooterFragment = ({ key, content }) =>
    content.map(
      item =>
        content !== 'English' && (
          <Box component="li" className="mb-3" key={item.name}>
            <Link
              href={{
                pathname: item.url,
                query: JSON.parse(
                  JSON.stringify({
                    type: item.type ? item.type : undefined
                  })
                )
              }}
              prefetch={false}
              onClick={() => {
                statsigLog('homepage_footer_detail_click', { footerType: key, detail: content })
              }}
            >
              {item.name}
            </Link>
          </Box>
        )
    )
  return (
    <Box className="bg-[#2A9A76] md:bg-white py-[40px] md:py-[90px] border-t md:max-w-[1160px] mx-auto">
      <Box className="flex flex-col justify-center items-center">
        <Box className="h-fit w-full max-w-[1160px] px-5 md:px-0 flex justify-between items-start gap-[154px] ">
          <Box className="hidden md:flex justify-between flex-grow">
            {footerContentList.map(column => (
              // if (!withBlog && column.key === 'blog') {
              //   return ''
              // }
              <Box key={column.key}>
                <Box component="h3" className="font-averta font-bold mb-7">
                  {column.name}
                </Box>
                <Box className="flex gap-10">
                  <Box
                    component="ul"
                    id={column.key}
                    className="font-averta leading-6 text-sm text-secondary"
                    key={column.name}
                  >
                    {FooterFragment(column)}
                    {/* {!expand && column.key === 'neighborhoods' && (
                      <Box component="li">
                        <Box onClick={onClickExpand} className="mb-3" component="button">
                          See all
                        </Box>
                      </Box>
                    )} */}
                  </Box>
                  {/* {expand && column.key === 'neighborhoods' && (
                    <>
                      <Box
                        component="ul"
                        id={column.key}
                        className="font-averta leading-6 text-sm text-secondary"
                        key="areaListOne"
                      >
                        {FooterFragment(areaListOne)}
                      </Box>
                      <Box
                        component="ul"
                        id={column.key}
                        className="font-averta leading-6 text-sm text-secondary"
                        key="areaListTwo"
                      >
                        {FooterFragment(areaListTwo)}
                        {!expand && (
                          <Box component="li">
                            <Box onClick={onClickExpand} className="mb-3" component="button">
                              See all
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  )} */}
                </Box>
              </Box>
            ))}
          </Box>
          <Box className="w-full md:w-fit flex text-secondary justify-center md:justify-start items-end flex-row md:flex-col gap-10 shrink-0">
            <Image
              width={51}
              height={51}
              alt="Equal Housing Opportunity"
              src={
                isPC
                  ? 'https://tripalink-public.s3-accelerate.amazonaws.com/ab6d94e104dfff4d.png'
                  : 'https://tripalink-public.s3-accelerate.amazonaws.com/fa1ea5d9be7d0d95.png'
              }
            />
            <Box className="gap-6 flex-row items-center text-white md:text-secondary">
              <Box className="flex flex-col gap-2">
                <Box className="gap-6 md:gap-4 flex justify-end">
                  {socialIcon('_bank', isPC ? '#717171' : 'white')}
                </Box>
                <Box className="text-[13px] font-normal font-averta">
                  ©️ {CURRENT_YEAR} Renty.AI Inc. All Rights Reserved
                </Box>
              </Box>
            </Box>
            <Box className="hidden md:block text-[13px] font-normal font-averta leading-7 w-[141px]">
              800 S Harvard Blvd, <br /> Los Angeles, CA 90005 <br /> marketing@Renty.ai
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FooterContent
