export default function twitter(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_13487_98714)">
        <path
          d="M24 12.5757C24 5.94841 18.6273 0.575684 12 0.575684C5.37273 0.575684 0 5.94841 0 12.5757C0 19.203 5.37273 24.5757 12 24.5757C18.6273 24.5757 24 19.203 24 12.5757ZM5.53855 16.84C5.74582 16.8662 5.95745 16.8804 6.17127 16.8804C7.41382 16.8826 8.55709 16.4342 9.46364 15.6815C8.30291 15.6542 7.32436 14.8339 6.98727 13.708C7.14873 13.7419 7.31564 13.7615 7.48582 13.7626C7.728 13.7637 7.96145 13.731 8.184 13.6677C6.97091 13.3993 6.05673 12.2484 6.05673 10.8771C6.05673 10.8651 6.05673 10.8531 6.05673 10.8411C6.41455 11.0571 6.82255 11.188 7.25782 11.2077C6.54764 10.6939 6.07964 9.82114 6.07964 8.83932C6.07964 8.32005 6.21055 7.83568 6.43855 7.42114C7.74655 9.15023 9.70036 10.2979 11.9029 10.4408C11.8571 10.2346 11.8342 10.0197 11.8342 9.80041C11.8342 8.25023 13.0211 7.01314 14.4851 7.03714C15.2476 7.05023 15.9371 7.40041 16.4204 7.95023C17.0247 7.83459 17.592 7.61205 18.1036 7.30114C17.9062 7.94805 17.4851 8.48696 16.9375 8.82405C17.4742 8.76405 17.9847 8.62223 18.4604 8.40732C18.1047 8.96041 17.6553 9.44368 17.1371 9.82987C17.1425 9.94987 17.1447 10.0699 17.1447 10.1899C17.1447 13.8706 14.4775 18.1153 9.60109 18.1131C8.10545 18.1142 6.71127 17.6451 5.53855 16.84Z"
          fill={props?.color || '#717171'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13487_98714">
          <rect width="24" height="24" fill="white" transform="translate(0 0.575684)" />
        </clipPath>
      </defs>
    </svg>
  )
}
