export default function youtube(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_13487_98719)">
        <path
          d="M18.336 15.4317C18.084 16.3685 17.7729 16.8237 16.908 16.9917C16.908 16.9917 14.6649 17.1605 12.324 17.1605C9.744 17.1605 7.032 16.9917 7.032 16.9917C6.156 16.8837 5.748 16.3077 5.55686 15.4317C5.55686 15.4317 5.30486 13.9197 5.30486 12.4197C5.30486 10.9677 5.55686 9.51654 5.55686 9.51654C5.80886 8.61654 6.048 8.06368 7.03114 7.95568C7.03114 7.95568 9.468 7.82368 11.928 7.82368C14.4129 7.82368 16.908 7.95568 16.908 7.95568C17.8449 8.06368 18.0609 8.58054 18.336 9.51654C18.336 9.51654 18.588 11.0885 18.588 12.6117C18.588 14.0397 18.336 15.4317 18.336 15.4317ZM12 0.575684C5.364 0.575684 0 5.94054 0 12.5757C0 19.1997 5.37686 24.5757 12 24.5757C18.624 24.5757 24 19.1997 24 12.5757C24 5.94054 18.624 0.575684 12 0.575684Z"
          fill={props?.color || '#717171'}
        />
        <path
          d="M10.5342 14.878L14.3742 12.3949L10.5342 9.90918V14.878Z"
          fill={props?.color || '#717171'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13487_98719">
          <rect width="24" height="24" fill="white" transform="translate(0 0.575684)" />
        </clipPath>
      </defs>
    </svg>
  )
}
