import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useLazyQuery } from '@apollo/client'
import { Skeleton, useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'
import HouseCard from '../houseCard'
import GUESS_YOU_LIKE_HOUSE from '../../graphqls/queryGuessYouLikeHouse.gql'
import { chatActiveProperty, chatActivePropertyList } from '../../state/chatActiveProperty'
import { propertyClick } from '../blog/constData'
import { getDetailPageUrlPath } from '../../lib/urlParse'

export const useGuessULike = () =>
  useLazyQuery(GUESS_YOU_LIKE_HOUSE, {
    variables: {
      filterBy: {
        channel: 'renty',
        uid: typeof window !== 'undefined' ? localStorage.getItem('VID') : ''
      }
    }
  })

const RecommendList = ({
  list = [],
  loading = false,
  eventName = 'blog_propertyRec_click',
  clickCallback = null
}) => {
  const router = useRouter()
  const matches = useMediaQuery('@media (min-width:768px)')
  const [slidesPerView, setSlidesPerView] = useState(matches ? 1.3 : 3.5)
  const listData = list

  const onClick = property => {
    propertyClick(eventName, property)
    chatActiveProperty(property)
    chatActivePropertyList(listData)
    const { city, area, name, id } = property
    const path = getDetailPageUrlPath(city, area, name, id)
    if (clickCallback) {
      clickCallback(property)
      return
    }
    router.push(path)
  }

  useEffect(() => {
    const { innerWidth } = window
    let curSlidesPerView = slidesPerView
    if (innerWidth < 768) {
      curSlidesPerView = 1.3
    } else if (innerWidth >= 768 && innerWidth < 1024) {
      curSlidesPerView = 2.4
    } else {
      curSlidesPerView = 3.5
    }
    setSlidesPerView(curSlidesPerView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches])

  return (
    <>
      <style jsx global>{`
        .guess-like {
          --swiper-theme-color: #fff;
          --swiper-navigation-color: #050a22;
          position: relative;
          --swiper-navigation-size: 0;
        }

        .guess-like .swiper-button-disabled {
          display: none;
        }
        .guess-like > .swiper-button-next,
        .guess-like > .swiper-button-prev {
          position: absolute;
          top: 0;
          width: 128px;
          height: 100%;
          pointer-events: none;
        }
        .guess-like > .swiper-button-prev {
          left: -2px;
          background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0%, #fff 47.4%);
        }
        .guess-like > .swiper-button-next {
          right: -2px;
          background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0%, #fff 47.4%);
        }

        .guess-like > .swiper-button-prev::before {
          left: 3px;
        }
        .guess-like > .swiper-button-next::before {
          right: 3px;
        }

        .guess-like > .swiper-button-prev::after {
          left: 3px;
          transform: translateX(20px);
        }
        .guess-like > .swiper-button-next::after {
          right: 3px;
          transform: translateX(-20px);
        }

        .guess-like > .swiper-button-next::before,
        .guess-like > .swiper-button-prev::before {
          background: #fff;
          content: '';
          display: inline-block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid #b0b0b0;
          position: absolute;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
          pointer-events: all;
        }

        .guess-like > .swiper-button-next::after,
        .guess-like > .swiper-button-prev::after {
          font-weight: 700;
          color: #717171;
          font-size: 14px;
          position: absolute;
          pointer-events: all;
        }

        .guess-like > .swiper-button-next:hover::before,
        .guess-like > .swiper-button-prev:hover::before {
          background: #e9e9e9;
        }

        .guess-like > .swiper-button-next:hover::after,
        .guess-like > .swiper-button-prev:hover::after {
          color: #001229;
        }
      `}</style>
      <Swiper
        autoHeight
        navigation={matches}
        freeMode
        modules={[Navigation, FreeMode]}
        className="guess-like w-full"
        slidesPerView={slidesPerView}
      >
        {loading
          ? [1, 2, 3].map(v => (
              <SwiperSlide key={v.toString()}>
                <Skeleton variant="rounded" width="100%" height={276} animation="wave" />
              </SwiperSlide>
            ))
          : listData.map((item, index) => (
              <SwiperSlide
                key={[index, '-'].join('-')}
                className="flex flex-col items-start px-2 box-border"
              >
                <HouseCard
                  className="w-full h-full box-border"
                  property={item}
                  allowTouchPicMove={false}
                  preLoadImg={false}
                  handleClick={() => onClick(item)}
                  isMapMode={false}
                />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  )
}

export default RecommendList
